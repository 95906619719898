import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { scroll } from '@wagmi/core/chains'

const icon = chainsSvgs.scrollSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...scroll,
    rpcUrls: {
      default: {
        http: [
          'https://scroll-mainnet.g.alchemy.com/v2/TqGM2FxF-bvxY6t0lN-J8c2nDidQtRCZ',
          'https://scroll-mainnet.g.alchemy.com/v2/DR7Jtd4NSYTtAY7Heme8ml-j6oBCZgGO',
          'https://rpc.ankr.com/scroll/c2d7e8a3db5dce62e202db3d28cca25e74da5028abbf20764e2961918ba34dfc',
          'https://rpc.ankr.com/scroll/29fb8c6c5a90691782b017d9b6ad609d5e67c33e9b0905cce2153cefd28d3b2d',
          'https://rpc.ankr.com/scroll'
        ]
      }
    }
  },
  id: 534_352,
  type: 'EVM',
  name: scroll.name,
  icon: icon,
  networkType
}

export default chainInfo
