import { FeeMode } from '@/components/FeeSelect'
import { makeAutoObservable } from 'mobx'

class CommonStore {
  balanceFetchSwitch: boolean = false
  viewHeight: number = 0
  staticHeight: number = 0
  mayKeyboardOpen: boolean = false
  feeMode: FeeMode = FeeMode.AVERAGE
  fullScreen: boolean = false
  platform: string = ''

  constructor() {
    makeAutoObservable(this)
  }

  feeModeActions(feeMode: FeeMode) {
    this.feeMode = feeMode
  }

  updatebalanceSwitch(flag: boolean) {
    this.balanceFetchSwitch = flag
  }
  updateViewHeightChange(num: number) {
    // console.log('this.viewHeight >= num', this.viewHeight, num)
    this.mayKeyboardOpen = this.staticHeight > num
    this.viewHeight = num
    if (!this.mayKeyboardOpen)
      window.scrollTo({
        top: window.Telegram?.WebApp?.isFullscreen ? -94 : 0,
        behavior: 'smooth'
      })
  }
  updateStaticViewHeight(num: number) {
    if (num > this.staticHeight) {
      this.staticHeight = num
    }
  }
  updateInitViewHeight(num: number) {
    this.staticHeight = num
    this.viewHeight = num
  }
  updateFullScreen(flag: boolean) {
    this.fullScreen = flag
  }
  updatePlatform(plat: string) {
    // this.platform = window.Telegram.WebApp.platform
    this.platform = plat
  }
}

const commonStore = new CommonStore()
export default commonStore
