import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { gravity } from '@wagmi/core/chains'

const icon = chainsSvgs.gravityJpg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...gravity,
    rpcUrls: {
      default: {
        http: [
          'https://rpc.ankr.com/gravity/5837c82e627408c9f6be9ab4e26603bd53f5cb93b8c5c3a7ce664feb32897d8c',
          'https://rpc.gravity.xyz'
        ]
      }
    }
  },
  id: gravity.id,
  type: 'EVM',
  name: gravity.name,
  icon: icon,
  networkType
}

export default chainInfo
