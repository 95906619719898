/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createOrder } from '@/api/ramp'
import { HistoryItem, QuoteSide, SupportPayWay } from '@/api/ramp/type'
import {
  alchemyPay,
  applePay,
  applePayDark,
  googlePay,
  mastercardSvg,
  visaSvg
} from '@/assets'
import Tip from '@/components/Tip'
import { TButton, TContainer } from '@/components/tmd'
import useLoginInfo from '@/hooks/useLoginInfo'
import { marketChain } from '@/proviers/web3Provider/chains'
import { Routers } from '@/router'
import rampStore from '@/stores/rampStore'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import useTheme from '@/stores/userStore/hooks/useTheme'
import { useMutation } from '@tanstack/react-query'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { Button, Radio } from 'antd-mobile'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from '@/components/tmd/toast/Toast'
import { Toast } from '@/components/tmd'
import { getCookieQuery } from './utils'

const PaymentMethod = [
  {
    type: 'creditCard',
    lable: 'Credit Card',
    payWayCode: SupportPayWay.CREDITCARD,
    icon: [visaSvg, mastercardSvg]
  },
  {
    type: 'applePay',
    lable: 'Apple Pay',
    payWayCode: SupportPayWay.APPLEPAY,
    icon: [applePay]
  }
  // {
  //   type: 'googlePay',
  //   lable: 'Google Pay',
  //   payWayCode: SupportPayWay.GOOGLEPAY,
  //   icon: [googlePay]
  // }
]

export const ChoosePaymentMethod: React.FC = () => {
  const { theme } = useTheme()
  const navigate = useNavigate()
  const type = rampStore.selectedTab
  const [disabled, setDisabled] = useState<boolean>(false)
  const [no, _] = useState<string>(
    `${Date.now()}${Math.floor(Math.random() * 10000)}`
  )
  const [amountError, setAmountError] = useState<string>('')
  // const [loading, setLoading] = useState(false)
  const [backShow, setBackShow] = useState(true)

  const getAddressByToken = useLoginInfo().getAddressByToken
  const clearAmount = rampStore.clearAmount
  const setHistoryList = rampStore.setHistoryList
  const selectCurrency =
    type === QuoteSide.BUY
      ? rampStore.buySelectedCurrency
      : rampStore.sellSelectedCurrency
  const quote =
    type === QuoteSide.BUY ? rampStore.buyQuote : rampStore.sellQuote
  const [seletedPaymentMethod, setSelectedPaymentMethod] = useState<
    SupportPayWay | undefined
  >(
    PaymentMethod.find(
      (item) =>
        rampStore.buySelectedCurrency.payWay
          ?.map((i) => i.payWayCode)
          ?.includes(item.payWayCode)
    )?.payWayCode
  )

  const validateAmount = useCallback(() => {
    const payWayObj = rampStore.buySelectedCurrency.payWay?.find(
      (i) => i.payWayCode === seletedPaymentMethod
    )
    if (!payWayObj) return
    const minBuy = Math.max(
      Number(payWayObj?.payMin),
      Number(rampStore.buySelectedCurrency?.cryptoDetail?.minPurchaseAmount)
    )
    const maxBuy = Math.min(
      Number(rampStore.buySelectedCurrency?.cryptoDetail?.maxPurchaseAmount),
      Number(payWayObj?.payMax)
    )
    if (new BigNumber(rampStore.buyAmount).gt(maxBuy)) {
      setAmountError(
        `The maximum purchase amount for this payment method is ${maxBuy}. Please reset your purchase amount.`
      )
      return false
    }
    if (new BigNumber(rampStore.buyAmount).lt(minBuy)) {
      setAmountError(
        `The minimum purchase amount for this payment method is ${minBuy}. Please reset your purchase amount.`
      )
      return false
    }
    setAmountError('')
  }, [seletedPaymentMethod])

  useEffect(() => {
    if (!seletedPaymentMethod) return
    validateAmount()
  }, [seletedPaymentMethod, validateAmount])

  const { mutate, isPending } = useMutation({
    mutationFn: createOrder,
    onSuccess: (data) => {
      Toast.clear()
      if (!data?.data) {
        toast.error(data?.returnMsg || 'Connect alchemy error, try again later')
        return
      }

      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')
      const hours = String(currentDate.getHours()).padStart(2, '0')
      const minutes = String(currentDate.getMinutes()).padStart(2, '0')
      const seconds = String(currentDate.getSeconds()).padStart(2, '0')

      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      const item: HistoryItem = {
        side: type,
        cryptoCurrency: rampStore.selectedToken.symbol,
        address: getAddressByToken(rampStore.selectedToken),
        tokenAddress: rampStore.selectedToken.address,
        network: marketChain[rampStore.selectedToken?.chainId].chain,
        amount: rampStore.buyAmount,
        fiatCurrency: selectCurrency.currency,
        orderType: '4',
        payWayCode: rampStore.selectPayWayCode,
        userAccountId: rampStore.alchemyUserAccountId.toString(),
        redirectUrl: '',
        callbackUrl: '',
        quote: quote,
        orderNo: data.data.orderNo,
        webUrl: data.data.webUrl,
        createTime: formattedDateTime,
        merchantOrderNo: no,
        txHash: ''
      }
      setHistoryList([item, ...rampStore.historyList])
      clearAmount()
      if (data.data.webUrl) {
        if (seletedPaymentMethod === SupportPayWay.CREDITCARD) {
          setBackShow(false)
        }
        window.location.href = data.data.webUrl
      } else {
        toast('create failed')
      }
    },
    onError: (e) => {
      Toast.clear()
      toast.error(e.message || 'Connect alchemy error, try again later')
      setDisabled(false)
    }
  })

  const onProceed = async () => {
    if (!seletedPaymentMethod) {
      return
    }
    rampStore.setSelectPayWayCode(seletedPaymentMethod)
    if (seletedPaymentMethod !== SupportPayWay.CREDITCARD) {
      Toast.show({
        icon: 'loading',
        content: '',
        duration: 0,
        maskClickable: false
      })

      let cookieQuery = {
        cookie: '',
        channalCookie: '',
        clientIp: ''
      }
      try {
        cookieQuery = await getCookieQuery()
      } catch (e) {
        Toast.clear()
        toast.error('Get alchemy cookies error, try again')
        return
      }

      if (!cookieQuery.channalCookie || !cookieQuery.cookie) {
        Toast.clear()
        toast.error('Please enable cookies')
        return
      }

      const jumpURL = import.meta.env.VITE_BOT_LINK + `?startapp=RAMP_${no}`

      mutate({
        side: type,
        merchantOrderNo: no,
        cryptoCurrency: rampStore.selectedToken.symbol,
        address: getAddressByToken(rampStore.selectedToken),
        network: marketChain[rampStore.selectedToken?.chainId].chain,
        amount: rampStore.buyAmount,
        fiatCurrency: selectCurrency.currency,
        orderType: '4',
        payWayCode: seletedPaymentMethod!,
        // redirectUrl: `https://tomo.inc/union?rampNo=${no}`,
        redirectUrl: `https://tomo.inc/union?url=${encodeURIComponent(
          jumpURL
        )}`,
        callbackUrl: '',
        extendParams: {
          cookie: cookieQuery.cookie,
          channelCookie: cookieQuery.channalCookie
        },
        clientIp: cookieQuery.clientIp
      })
      return
    }
    navigate(Routers.ramp.cardDetails)
  }
  return (
    <>
      {backShow && <BackButton onClick={() => navigate(-1)} />}
      <TContainer className="flex flex-1 flex-col">
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col">
            <div className="flex h-[52px] items-center">
              <span className="text-xl font-semibold text-t1">
                Choose payment method
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <Radio.Group
                value={seletedPaymentMethod}
                onChange={(val) => {
                  setSelectedPaymentMethod(val as SupportPayWay)
                }}
              >
                {PaymentMethod.map((item) => (
                  <Radio
                    style={{
                      '--icon-size': '16px',
                      '--gap': '0px'
                    }}
                    icon={(checked) => <></>}
                    disabled={
                      !rampStore.buySelectedCurrency.payWay
                        ?.map((i) => i.payWayCode)
                        .includes(item.payWayCode)
                    }
                    key={item.payWayCode}
                    value={item.payWayCode}
                    className={classNames(
                      'p-4 rounded-lg bg-bg3',
                      seletedPaymentMethod === item.payWayCode &&
                        'border border-primary',
                      !rampStore.buySelectedCurrency.payWay
                        ?.map((i) => i.payWayCode)
                        .includes(item.payWayCode) && ' opacity-100 '
                    )}
                  >
                    <div className="flex w-full items-center justify-between">
                      <span
                        className={classNames(
                          'text-base font-medium text-t1',
                          !rampStore.buySelectedCurrency.payWay
                            ?.map((i) => i.payWayCode)
                            .includes(item.payWayCode) && ' !text-t3'
                        )}
                      >
                        {item.lable}
                      </span>
                      <div className="flex gap-2">
                        {item.type === 'creditCard' ? (
                          item.icon.map((icon, index) => (
                            <img className="h-5" src={icon} key={index} />
                          ))
                        ) : theme === 'light' ? (
                          <img className="h-5" src={applePay} />
                        ) : (
                          <img className="h-5" src={applePayDark} />
                        )}
                      </div>
                    </div>
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
          <div className="flex flex-col">
            {!!amountError && <Tip content={amountError} />}
            <TButton
              size="large"
              onClick={onProceed}
              color="primary"
              className="text-base font-medium"
              disabled={!seletedPaymentMethod || disabled || !!amountError}
              // loading={isPending}
            >
              Proceed
            </TButton>
            <div className="my-[6px] flex items-center justify-center">
              <span className="text-sm text-t3">Powered by</span>
              <img className="h-5" src={alchemyPay} alt="" />
            </div>
          </div>
        </div>
      </TContainer>
    </>
  )
}
