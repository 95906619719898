import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { avalanche } from '@wagmi/core/chains'

const icon = chainsSvgs.avaxSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...avalanche,
    rpcUrls: {
      default: {
        http: [
          'https://avax-mainnet.g.alchemy.com/v2/DR7Jtd4NSYTtAY7Heme8ml-j6oBCZgGO',
          'https://rpc.ankr.com/avalanche/c2d7e8a3db5dce62e202db3d28cca25e74da5028abbf20764e2961918ba34dfc',
          'https://rpc.ankr.com/avalanche',
          'https://rpc.ankr.com/avalanche/57f10f5dfc7ba8d80707c85c1cc1d5feeda3c1c7447da479c55156196d816235',
          'https://rpc.ankr.com/avalanche/ac79e83cf02a544dbb9b3f4c5d5478b2510b921e7d5739ded8791a932e8de0a6'
        ]
      }
    }
  },
  id: 43_114,
  type: 'EVM',
  name: avalanche.name,
  icon: icon,
  networkType
}

export default chainInfo
