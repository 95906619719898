import PopupComponent from '../utils/Popup'
import React, { useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import PaypinVerify from '../components/PaypinVerify'
import { errorContents } from '@/config/const'

const usePaypinVerify = () => {
  const showPopup = () => {
    let closePopup: () => void // 保存关闭弹窗的函数
    const promise = new Promise((resolve, reject) => {
      const div = document.createElement('div')
      document.body.appendChild(div)

      const root = createRoot(div)

      const PopupWrapper = () => {
        const [visible, setVisible] = useState(true)
        const location = useLocation()
        const isOpen = useRef(false)

        useEffect(() => {
          closePopup = () => {
            setVisible(false)
            setTimeout(() => {
              try {
                root.unmount()
                if (document.body.contains(div)) {
                  document.body.removeChild(div)
                }
              } catch (e) {
                //
              }

              resolve('closed')
            }, 300)
          }
        }, [])

        useEffect(() => {
          if (isOpen.current) {
            handleCancel()
          }

          console.log('location', location)
        }, [location])
        useEffect(() => {
          isOpen.current = visible
        }, [visible])

        const onSuccess = (mfa: string) => {
          // setVisible(false)
          // setTimeout(() => {
          // root.unmount()
          // document.body.removeChild(div)
          resolve(mfa)
          // }, 300)
        }

        const handleCancel = () => {
          setVisible(false)
          setTimeout(() => {
            try {
              root.unmount()
              if (document.body.contains(div)) {
                document.body.removeChild(div)
              }
            } catch (e) {
              //
            }
            reject(errorContents.userErrors.payPinCanceled)
          }, 300)
        }

        const onFailed = (err: string) => {
          console.log('errerr', err)
          // reject(err)
        }

        return (
          <PopupComponent
            title="Confirm pay PIN"
            content={
              <PaypinVerify
                onSuccess={onSuccess}
                onFailed={onFailed}
                titleFlag={false}
              />
            }
            visible={visible}
            onClose={handleCancel}
          />
        )
      }

      root.render(
        <Router>
          <PopupWrapper />
        </Router>
      )
    })
    return {
      promise,
      close: () => closePopup()
    }
  }

  return { showPopup }
}

export default usePaypinVerify
