import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'

const icon = chainsSvgs.doge
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    id: 3,
    name: 'DOGE',
    nativeCurrency: { decimals: 8, name: 'DOGE', symbol: 'DOGE' }
  },
  id: 3,
  type: 'DOGE',
  name: 'Dogecoin',
  icon: icon,
  networkType
}

export default chainInfo
