import { getPayload as getPayloadApi } from 'api/sdk'
import { useNavigate } from 'react-router-dom'
import { decodePayload } from 'utils/sdk'

export default function useSdk() {
  const navigate = useNavigate()
  const getPayload = async () => {
    try {
      const { payload_base64 } = await getPayloadApi()
      return decodePayload(payload_base64)
    } catch (err: any) {
      console.log('err', err)
      if (err && (err as { status: number }).status === 401) {
        navigate('/oauth/expired')
        return err
      }
      throw err
    }
  }

  return { getPayload }
}
