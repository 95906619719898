import useLoginInfo from '../useLoginInfo'
// import usePopup from './usePopup'
import usePopup from '@/hooks/popup/usePopup'
import QRCode from 'react-qr-code'
import CopyToClipboard from 'react-copy-to-clipboard'
import useBackup from '@/pages/login/hooks/useBackup'
import { evmSvg } from '@/assets'
import { TIcon, TButton, TToast as toast } from '@/components/tmd'

export const usePopupAddressQRCode = ({
  address,
  qrcodeIcon,
  copyable,
  fullscreen,
  symbol,
  chainType,
  btcWalletType
}: {
  address: string | undefined
  qrcodeIcon?: string | undefined
  copyable?: boolean
  fullscreen?: boolean
  symbol?: string | undefined
  chainType?: string | undefined
  btcWalletType?: string | undefined
}) => {
  const { user } = useLoginInfo()
  const onCopy = () => {
    return toast.success('Copied to clipboard successfully')
  }
  const { setOpen, component } = useBackup({
    onSkip: () => {
      setOpen(false)
    }
  })

  return usePopup({
    showCloseButton: true,
    maskGesture: true,
    closeIcon: (
      <TIcon name="tg_wallet_disable" className="text-t1" fontSize="20" />
    ),
    className: 'email-popup',
    fullscreen: true,
    contentClassName: 'flex flex-col justify-between',
    content: (
      <>
        {component}
        <div className={`flex size-full flex-col items-center`}>
          <div
            className={`relative mb-[6px] mt-[8px] flex size-[160px] items-center justify-center bg-white `}
          >
            {address && <QRCode value={address} className={`size-[138px]`} />}
          </div>

          <div className="flex w-full flex-1 flex-col justify-end">
            <div className="mb-[30px] flex flex-col">
              <div className="flex items-center gap-[8px]">
                {qrcodeIcon &&
                  (chainType == 'EVM' ? (
                    <img src={evmSvg} className="h-[32px]" />
                  ) : (
                    <img
                      src={qrcodeIcon}
                      className={`size-[32px] rounded-full`}
                    />
                  ))}
                <span className="text-xl font-bold text-t1">
                  {chainType == 'EVM' ? 'EVM' : symbol}
                </span>
                {!!btcWalletType && (
                  <span className="rounded-[5px] bg-bg3  px-[4px] py-[3px] text-xs text-t2">
                    {btcWalletType}
                  </span>
                )}
              </div>
              <div className={`mt-2 flex w-full items-start gap-[8px]`}>
                <div className="min-w-0 flex-1 break-all text-sm text-t3">
                  {address}
                </div>
                {/* {copyable && address && (
                  <CopyToClipboard text={address} onCopy={onCopy}>
                    <CopySvg
                      width={16}
                      height={16}
                      className={'mt-[2px] cursor-pointer'}
                    />
                  </CopyToClipboard>
                )} */}
              </div>
            </div>
            {copyable && (
              <div className={`w-full`}>
                {address && (
                  <div className="flex w-full">
                    <CopyToClipboard text={address} onCopy={onCopy}>
                      <TButton
                        className="w-full"
                        size="large"
                        onClick={() => () => {
                          if (!user?.email) {
                            setOpen(true)
                          }
                        }}
                      >
                        Copy Address
                      </TButton>
                    </CopyToClipboard>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    )
  })
}
