import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { arbitrum } from '@wagmi/core/chains'

const icon = chainsSvgs.arbJpeg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...arbitrum,
    rpcUrls: {
      default: {
        http: [
          'https://arb-mainnet.g.alchemy.com/v2/DR7Jtd4NSYTtAY7Heme8ml-j6oBCZgGO',
          'https://arbitrum-mainnet.infura.io/v3/e42637ee1f664cad93e70bbf62196769',
          'https://rpc.ankr.com/arbitrum/e4ee651c8e8d46257a5e9cd1b80d29b39e4b988f14e5ea8589b5452a8131abba',
          'https://arb-mainnet.g.alchemy.com/v2/BaBkGlhFa9kh4yi9OLmaeQlKZtvaLgi-'
        ]
      }
    }
  },
  id: 42_161,
  type: 'EVM',
  name: arbitrum.name,
  icon: icon,
  networkType: networkType
}

export default chainInfo
