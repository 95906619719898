import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { bsquared } from '@wagmi/core/chains'

const icon = chainsSvgs.b2Svg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...bsquared,
    rpcUrls: {
      default: {
        http: [
          'https://rpc.ankr.com/b2/82af823a605e3d780f91b7dede4fd56bbb3058f26cd90dc3a31f355c699b94d5',
          'https://rpc.bsquared.network'
        ]
      }
    }
  },
  id: bsquared.id,
  type: 'EVM',
  name: bsquared.name,
  icon: icon,
  networkType
}

export default chainInfo
