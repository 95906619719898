import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { optimism } from '@wagmi/core/chains'

const icon = chainsSvgs.opSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...optimism,
    rpcUrls: {
      default: {
        http: [
          'https://opt-mainnet.g.alchemy.com/v2/oYu8Ai2M85roqxhvHxBMdLjqs8Kh-4qt',
          'https://optimism-mainnet.infura.io/v3/e42637ee1f664cad93e70bbf62196769',
          'https://rpc.ankr.com/optimism/c626b2acc7d5145c9b392e8aff82409e2c61444b24a36be4cceed74f29eedaba',
          'https://opt-mainnet.g.alchemy.com/v2/DR7Jtd4NSYTtAY7Heme8ml-j6oBCZgGO'
        ]
      }
    }
  },
  id: 10,
  type: 'EVM',
  name: 'Optimism',
  icon: icon,
  networkType
}

export default chainInfo
