import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { worldchain } from '@wagmi/core/chains'

const icon = chainsSvgs.worldChainPng
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...worldchain,
    rpcUrls: {
      default: {
        http: [
          'https://worldchain-mainnet.g.alchemy.com/v2/DR7Jtd4NSYTtAY7Heme8ml-j6oBCZgGO',
          'https://worldchain-mainnet.g.alchemy.com/public'
        ]
      }
    }
  },
  id: worldchain.id,
  type: 'EVM',
  name: worldchain.name,
  icon: icon,
  networkType
}

export default chainInfo
